import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./HuntFinished.library.scss"

// Import component specific styles.
import styles from "./HuntFinished.module.scss"

const HuntFinished = props => (
  <GridContainer>
    <div className={styles.huntFinishedWrapper}>
      <div className={styles.logo}>
        <Link to="/" aria-label="Navigate to the Home page"><img src={props.logoSrc} alt={props.logoAlt} /></Link>
      </div>
      <div className={styles.huntFinishedContent}>
        <h1 className={styles.huntFinishedTitle + " h1Heavy"}>{props.title}</h1>
        <p className={styles.pageText + " listTimeText"}>{props.pageText1}</p>
        <p className={styles.pageText + " listTimeText"}>{props.pageText2}</p>
        <div className={styles.ctaLink}><Link to={props.link} aria-label={'Navigate to the ' + props.linkText + ' page'}>{props.linkText}</Link></div>
      </div>
    </div>
  </GridContainer>
)

HuntFinished.propTypes = {
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  title: PropTypes.string,
  pageText1: PropTypes.string,
  pageText2: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

HuntFinished.defaultProps = {
  logoSrc: ``,
  logoAlt: ``,
  title: ``,
  pageText1: ``,
  pageText2: ``,
  linkText: ``,
  link: ``,
}

export default HuntFinished
