import React from "react"
import { graphql } from "gatsby"
import HuntFinished from "../components/HuntFinished/HuntFinished"
import Logo from '../images/ds_logo_crop.svg';
import Layout from "../components/layout/Layout/Layout";

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($journeyId: String!) {
    datoCmsJourney(id: { eq: $journeyId }) {
      title
      slug
      completionMessage
      socialSharingMessage
      secretHashtag
    }
  }
`

const logo = {
  src: Logo,
  alt: 'Destination Selsey Logo',
};

const JourneyEnd = props => {
  return (
    <Layout metaTitle={props.data.datoCmsJourney.title + " completed"} removeNav={true}>
      <HuntFinished
        logoSrc={logo.src}
        logoAlt={logo.alt}
        title="Congratulations!"
        pageText1={props.data.datoCmsJourney.completionMessage}
        pageText2="Now you can learn the secret hashtag :)"
        linkText="Secret Hashtag"
        link={props.data.datoCmsJourney.slug + '/hashtag'}
      />
    </Layout>
  )
}

export default JourneyEnd
